import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import useScrollToTop from '../../utils/useScrollToTop';
import MainMenu from '../../components/MainMenu';

const Project = () => {
  useScrollToTop()

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 10, padding: 10 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link to="/home">
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: '#c3e3ff', padding: 4 }}>
        <h2 style={{ fontFamily: 'Outfit' }}>Contact</h2>
      </Card>
      <Card>
        <h2>Support</h2>
        <p>
          Please send an email to <a href="mailto:resz.mzahid@gmail.com">resz.mzahid@gmail.com</a> for any complaints, bug reports, suggestions, refund requests, or if you require assistance using the app.
        </p>
      </Card>
      <Card>
        <h2>Hire me!</h2>
        <p>
          I'm also available for hire! Here's my <a href="https://mhmz.one/">portfolio</a>.
        </p>
      </Card>
    </div>
  );
}

export default Project;
