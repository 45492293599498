import React from 'react';
import { Button, Input } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { get, post } from "../../utils/fetch";

const HomePage = () => {
  const history = useHistory();
  const isAuthenticated = localStorage.getItem("accessToken");

  return (
    <div style={{ overflow: 'hidden' }}>
      <div style={{ padding: 20, display: 'flex', justifyContent: 'right' }}>
        <Button type='link' onClick={() => { history.push('/contact') }}>Contact</Button>
        <Button type='primary' onClick={() => { history.push(isAuthenticated ? '/user/projects' : `/signin`) }} style={{ color: 'white' }}>
          {isAuthenticated ? 'Dashboard' : 'Sign in'}
        </Button>
      </div>
      <div
        style={{
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <h1 style={{ fontSize: window.innerWidth > 900 ? 90 : 50 }}>Notaminda</h1>
        <div style={{ fontSize: 26, textAlign: 'center', padding: 20 }}>AI Powered Mind Map Generator</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%', padding: 10, maxWidth: 900, position: 'relative' }}>
          <img style={{ width: '100%' }} src="/hero.png" />
          <div style={{ position: 'absolute', top: -6, right: 26, display: 'flex', gap: 5 }}>
            <a href="https://github.com/rszhd/notaminda-rest-api">
              <Button style={{ border: '1px solid #1677ff' }} size='large'>Github</Button>
            </a>
            <Button type='primary' onClick={() => { history.push('user/project/bf77326e-773f-4676-abc8-9d165aadbc81/preview') }} size='large'>Try now</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage